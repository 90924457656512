import client from '.'

import { IReport, ImportBreadline } from '@src/app/pages/reports/models/reportModel'


export const reportService = async (params: IReport) => {
    await client.post('/reports', params)
}

export const archivingService = async () => {
    const res = await client.post('/reports/archive');
    return res
}

export const importBreadlineService = async (params: ImportBreadline) => {
    await client.post('/reports/breadline', params)
}